import React from 'react'
import { Content, Flex, Grid, Header } from 'everchain-uilibrary'

interface PageProps {
  children: React.ReactNode
  title: string
  subtitle: string
  customBackUrl?: string
}
const Page: React.FC<PageProps> = ({
  children,
  title,
  subtitle,
  customBackUrl,
}) => {
  return (
    <Flex>
      <Content>
        <div style={{ marginBottom: '15px' }}>
          <Grid item xs={12}>
            <Header
              title={title}
              subtitle={subtitle}
              customBackUrl={customBackUrl}
            />
          </Grid>
        </div>
        <div style={{ padding: '10px' }}>{children}</div>
      </Content>
    </Flex>
  )
}
export default Page
