import { BACKEND_URL } from 'src/infra/http/httpClient'

const strategyPrefix = 'strategies.'

export const StrategyGetStrategies = `${BACKEND_URL}${strategyPrefix}getStrategies`
export const StrategyGetStrategyById = `${BACKEND_URL}${strategyPrefix}getStrategyById`
export const StrategyGetStrategyByCreditorId = `${BACKEND_URL}${strategyPrefix}getStrategyByCreditorId`

export const StrategyGetStrategyStage = `${BACKEND_URL}${strategyPrefix}getStrategyStage`
export const StrategyGetStrategyStageByStrategyId = `${BACKEND_URL}${strategyPrefix}getStrategyStageByStrategyId`
export const StrategyStageTotalDistribution = `${BACKEND_URL}${strategyPrefix}getStrategyStageTotalDistribution`
