/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import {
  Form,
  FormInput,
  FormDropdown,
  Box,
  FormModalSubmitButton,
} from 'everchain-uilibrary'
import { ValidationRulesDictionary } from 'src/validation/validation'
import { getValidation } from 'src/validation/utils'
import { useMessageSnackbar } from 'src/utils/notification'
import { StrategyStage } from 'src/domain/models/strategy'
import { IStrategyPostOperations } from 'src/domain/features/post/strategy/strategy'
import { orders } from 'src/utils/constants'
import { Skeleton } from '@mui/material'

interface StrategyParams {
  id: number
  strategyStagesPostOperations?: IStrategyPostOperations
  strategyStageValidation?: ValidationRulesDictionary | undefined
  ordersCreated: number[]
  onCompleted: () => void
  isEditing?: boolean
  editStrategyData?: StrategyStage | undefined
}

const StrategyStageForm: React.FC<StrategyParams> = ({
  id,
  strategyStageValidation,
  strategyStagesPostOperations,
  ordersCreated,
  onCompleted,
  isEditing = false,
  editStrategyData,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showSuccessMessage } = useMessageSnackbar()
  const getOrderAvailable = (value?: any) => {
    let filteredOrders = [
      orders.find((order) => !ordersCreated.includes(Number(order.value))),
    ]
    if (value) {
      filteredOrders = filteredOrders.concat(
        orders.find((x) => x.value === value) || new Option()
      )
    }

    return filteredOrders
  }
  const onSubmitStage = (data: any) => {
    setLoading(true)

    var newStrategyStage: StrategyStage = {
      description:
        orders.find((d) => d.value === data.description)?.label || '',
      duration: data.duration,
      expanded: false,
      id: data.id || 0,
      order: Number(orders.find((d) => d.value === data.description)?.value),
      strategyId: id,
    }

    isEditing
      ? strategyStagesPostOperations
          ?.editStrategyStages(newStrategyStage)
          .then((response: any) => {
            showSuccessMessage('Strategy stage updated successfully')
            setLoading(false)
            onCompleted()
          })
          .catch((error) => {
            setLoading(false)
          })
      : strategyStagesPostOperations
          ?.addStrategyStages(newStrategyStage)
          .then((response: any) => {
            showSuccessMessage('Strategy stage created successfully')
            setLoading(false)
            onCompleted()
          })
          .catch((error) => {
            setLoading(false)
          })
  }

  const [formStrategy, setFormStrategy] = useState<StrategyStage>({
    description: isEditing
      ? orders.find((x) => x.label === editStrategyData?.description)?.value
      : '',
    duration: isEditing ? editStrategyData?.duration : undefined,
    expanded: false,
    id: isEditing ? editStrategyData?.id : 0,
    order: undefined,
    strategyId: id,
  })

  useEffect(() => {
    if (!isEditing || formStrategy.id !== editStrategyData?.id) {
      setFormStrategy({
        description: undefined,
        duration: undefined,
        expanded: false,
        id: 0,
        order: undefined,
        strategyId: id,
      })
    }
  }, [isEditing, editStrategyData?.id])

  return (
    <Form initialValues={formStrategy} onSubmit={onSubmitStage}>
      <Box>
        <Box>
          {loading ? (
            <Skeleton width={'20rem'} height={'2rem'}></Skeleton>
          ) : (
            <FormDropdown
              disabled={isEditing}
              id="description-dropdown"
              validation={getValidation(strategyStageValidation, 'order')}
              name="description"
              placeholder="Description"
              width="20rem"
              options={getOrderAvailable(formStrategy.description)}
            />
          )}
        </Box>
        <Box mt={3}>
          <FormInput
            id="duration-days-input"
            validation={getValidation(strategyStageValidation, 'duration')}
            placeholder="Duration (days)"
            name="duration"
            type="number"
            width="20rem"
          />
        </Box>
      </Box>
      {isEditing ? (
        <FormModalSubmitButton disabled={false} text="Save" />
      ) : (
        <FormModalSubmitButton disabled={loading || false} />
      )}
    </Form>
  )
}

export default StrategyStageForm
