import { authUrl, clientId, rmsClientId } from 'src/configs/authConst'
import { BASE_ROUTE } from 'src/presentation/routes'
import { Permissions } from '../context/Interfaces'

export const isUkCountry = (country: string | undefined) => {
  return country !== undefined ? country.toUpperCase() === 'UK' : false
}
export interface Options {
  label: string
  value: string
}
export interface RowProps {
  [field: string]: any
}
export interface ActionsMenu {
  onClick: (row: RowProps) => void
  name: string
  loading?: boolean
}

export interface CellTableActionsProps {
  rowProps: any
  actionsMenu: ActionsMenu[]
}

export const isRMSEnabled = (): boolean => {
  return process.env.REACT_APP_RMS_ENABLED === 'Y'
}

export const getStandardUri = (uri: string): string => {
  if (uri) {
    const baseRoute = isRMSEnabled() ? BASE_ROUTE : ''
    return `${baseRoute}${uri}`
  }

  return uri
}

export const isRecoverRoute = (pathName: string) => {
  return pathName.includes(`${BASE_ROUTE}/`)
}

interface PermissionReduce {
  [field: string]: boolean
}

export const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce => {
  return permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )
}

export const getLocalStorageUser = (): string | null => {
  const standardClientId = isRMSEnabled() ? rmsClientId : clientId

  return window.localStorage.getItem(
    `dt.user:${authUrl}:${standardClientId}` || ''
  )
}
