import React, { createContext, useContext, ReactNode, useReducer } from 'react'
import { AuthContext } from './AuthenticationContext'

type BusinessType = 'vendor' | 'creditor' | 'internal' | ''

type SetBusinessTypeAction = {
  type: 'SET_TYPE'
  payload: BusinessType
}

type Action = SetBusinessTypeAction

type State = {
  selectedType: BusinessType
  isCreditor: boolean
  isVendor: boolean
}

type BusinessTypeContextType = State & {
  dispatch: React.Dispatch<Action>
}

const BusinessTypeContext = createContext<BusinessTypeContextType | undefined>(
  undefined
)

const businessTypeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_TYPE':
      return {
        ...state,
        selectedType: action.payload,
        isVendor: action.payload === 'vendor',
        isCreditor: action.payload === 'creditor',
      }
    default:
      return state
  }
}
const findBusinessType = (userType: string): BusinessType => {
  const lowerCaseUserType = userType.toLowerCase()
  if (lowerCaseUserType === 'internal') {
    return 'internal'
  } else if (lowerCaseUserType === 'buyer') return 'creditor'

  return 'vendor'
}

// No specific type found, return a default value or handle this case as needed
export const BusinessTypeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const storedSelectedType = localStorage.getItem(
    'selectedBusinessType'
  ) as BusinessType
  const { userPermissions } = useContext(AuthContext)
  const initialSelectedType: BusinessType =
    storedSelectedType || findBusinessType(userPermissions.type)
  if (initialSelectedType)
    localStorage.setItem('selectedBusinessType', initialSelectedType)

  const [state, dispatch] = useReducer(businessTypeReducer, {
    selectedType: initialSelectedType,
    isVendor: initialSelectedType === 'vendor',
    isCreditor: initialSelectedType === 'creditor',
  })

  return (
    <BusinessTypeContext.Provider value={{ ...state, dispatch }}>
      {children}
    </BusinessTypeContext.Provider>
  )
}

export const useGetUserBusinessType = (): BusinessTypeContextType => {
  const contextValue = useContext(BusinessTypeContext)
  if (!contextValue) {
    throw new Error(
      'useGetUserBusinessType must be used within a BusinessTypeProvider'
    )
  }
  return contextValue
}

// Action creator for setting business type
export const setBusinessType =
  (dispatch: React.Dispatch<SetBusinessTypeAction>) => (type: BusinessType) => {
    dispatch({ type: 'SET_TYPE', payload: type })
    localStorage.setItem('selectedBusinessType', type)
  }
