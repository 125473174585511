import React from 'react'
import { render } from 'react-dom'
import * as serviceWorker from './serviceWorker'
import Root from './presentation/root'
import ErrorBoundary from './presentation/Error'

render(
  <React.StrictMode>
    <ErrorBoundary>
      <Root />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
