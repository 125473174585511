export const transformPropertiesToLowercase = (obj: any) => {
  if (typeof obj !== 'object') {
    return obj
  }
  const transformedObject: any = {}

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      transformedObject[key.toLowerCase()] = obj[key]
    }
  }

  return transformedObject
}
