/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Dropdown } from 'everchain-uilibrary'

import { Skeleton, TextField } from '@mui/material'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { FormFilter } from '..'

interface Options {
  label: string
  value: string
}
interface FileTypeFiltersParams {
  fileOperations?: IFileGetOperations
  onFileTypeUpdate: (fileType: any) => void
  selectedFileType?: string
  form: FormFilter | undefined
}
const FileTypesFilters: React.FC<FileTypeFiltersParams> = ({
  fileOperations,
  onFileTypeUpdate,
  form,
}) => {
  const [loadingFileTypes, setLoadingFileTypes] = useState<boolean>(false)
  const [fileTypes, setFileTypes] = useState<any>()

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: item.name,
        value: item.id,
      })
    )
    return newOptions
  }

  const GetVendors = async () => {
    setLoadingFileTypes(true)
    try {
      setFileTypes(await fileOperations?.getFileTypeData())
      setLoadingFileTypes(false)
    } catch (err) {
      console.log(err)
      setLoadingFileTypes(false)
    }
  }

  const options = getDropDownOptions(fileTypes || [])

  useEffect(() => {
    GetVendors()
  }, [])

  if (loadingFileTypes) return <Skeleton width="380px" height={80} />
  return (
    <Box display="flex" alignItems="flex-end" style={{ gap: 5 }}>
      {!options || options?.length === 0 ? (
        <></>
      ) : options && options.length > 1 ? (
        <Dropdown
          maxwidth="200px"
          width="200px"
          id="select-fileType"
          data-test-id="select-fileType"
          data-cy="select-fileType"
          options={options}
          value={form?.fileType || undefined}
          onChange={(option?: Options) => {
            onFileTypeUpdate(option?.value)
          }}
          placeholder="File type"
        />
      ) : (
        <TextField
          data-test-id="input-fileType"
          id="input-fileType"
          disabled={true}
          fullWidth
          data-cy="input-fileType"
          label="Vendor"
          name="fileType"
          variant="standard"
          value={options[0].label ?? ''}
        />
      )}
    </Box>
  )
}

export default FileTypesFilters
