import { Buffer } from 'buffer'

export const generateFileBlob = (template: any) => {
  if (template) {
    const byteCharacters = Buffer.from(template.file, 'base64').toString(
      'latin1'
    )
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const arrayBuffer = byteArray.buffer
    const blob = new Blob([arrayBuffer], {
      type: template.fileMime,
    })

    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = template.fileName
    a.click()
  }
}
