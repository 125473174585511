import { getLocalStorageUser } from './common'

export const GetAccessToken = (): string => {
  const currentUser = getLocalStorageUser()

  return currentUser ? `bearer ${JSON.parse(currentUser).access_token}` : ''
}
export const DownloadFileUri = (fileUri: string): void => {
  if (fileUri) window.location.href = fileUri
}
