import React from 'react'
import { makeBusinessGetData } from 'src/main/factories/feature/get/Business'
import { makeBusinessPostData } from 'src/main/factories/feature/post/Business'
import BusinessSettings from 'src/presentation/pages/BusinessSettings'

const makeBusinessSettings: React.FC = () => {
  return (
    <BusinessSettings
      businessGetOperations={makeBusinessGetData()}
      businessPostOperations={makeBusinessPostData()}
    />
  )
}

export default makeBusinessSettings
