import {
  ValidationRules,
  ValidationRulesDictionary,
} from 'src/validation/validation'

const makeStrategyValidation: ValidationRulesDictionary = {
  assetTypeId: new ValidationRules().required('Asset Type is required'),
  creditorId: new ValidationRules().required('Creditor is required'),
  name: new ValidationRules().required('Name is required'),
  //.minLength(5, 'Name should be at least 5 characters long')
  //.pattern(
  // /^[a-zA-Z\d\s]+$/i,
  //   'Password should contain only letters, numbers, or spaces'
  //),
}
export default makeStrategyValidation
