import React from 'react'
import { makeFilesData } from 'src/main/factories/feature/get/Files'
import { makeAccountPostData } from 'src/main/factories/feature/post/Account'
import AccountFileValidation from 'src/presentation/pages/Accounts/LoadAccounts/AccountFileValidation'

const makeAccountFileValidation: React.FC = () => {
  return (
    <AccountFileValidation
      fileOperations={makeFilesData()}
      accountPostOperations={makeAccountPostData()}
    />
  )
}

export default makeAccountFileValidation
