import { ThemeOptions } from '@material-ui/core'

export const debtTea = '#002E3A'
export const debtGreyLight = '#e7f7ff'
export const debtGreyLightDark = '##e7f7ff'
export const debtWhite = '#FFFFFF'
export const debtBlack = '#002E3A'
export const debtGreyClean = '#D6D4D3'
export const debtGrey = '#e7f7ff'
export const titleGrey = '#656565'
export const error = '#E03F3F'
export const white = '#FFFFFF'
export const debtGreyDark = '#323232'
export const debtBlue = '#82C2E1'
export const debtLightBlue = '#f3fafe'

interface ThemeDefaultOptions extends ThemeOptions {
  debtColors: {
    white: string
    tealTransparent: string
    debtTea: string
    debtGrey: string
    debtGreyLight: string
    debtGreyLightDark: string
    debtGreyClean: string
    error: string
    debtGreyDark: string
    debtBlue: string
    debtLightBlue: string
  }
}

const defaultTheme: ThemeDefaultOptions = {
  palette: {
    common: {
      black: debtTea,
    },
    primary: {
      main: debtTea,
      contrastText: debtWhite,
    },
    secondary: {
      main: debtTea,
      contrastText: debtWhite,
    },
  },
  debtColors: {
    white: '#FFFFFF',
    debtTea,
    debtGreyLight,
    debtGreyLightDark,
    tealTransparent: '#002E3A',
    debtGreyClean: '#DEDEDE',
    debtGrey,
    error,
    debtGreyDark,
    debtBlue,
    debtLightBlue,
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightLight: 300,
    h1: {
      fontSize: 24,
      fontWeight: 400,
    },
    h2: {
      color: debtTea,
      fontSize: 18,
      fontWeight: 500,
    },
  },
  spacing: 4,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: debtWhite,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: debtTea,
        color: '#222',
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: white,
        color: debtBlack,
      },
    },
    MuiButton: {
      text: {
        textTransform: 'none',
        fontWeight: 400,
      },
      contained: {
        textTransform: 'none',
        fontWeight: 400,
      },
      outlined: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },
    MuiTypography: {
      root: {
        fontSize: '0.9rem',
        lineHeight: '1rem',
        textTransform: 'none',
        color: '#000',
      },
      body1: { fontSize: '0.9rem' },
      body2: { fontSize: '0.9rem' },
      subtitle1: { fontSize: '1.5rem' },
      subtitle2: { fontWeight: 700 },
      h6: { fontSize: '1rem' },
    },
    MuiInputBase: {
      root: { fontSize: '0.9rem' },
    },
    MuiFormLabel: {
      root: { fontSize: '0.9rem' },
    },
    MuiTableCell: {
      root: { fontSize: '0.9rem' },
    },
    MuiTooltip: {
      tooltip: { fontSize: '0.75rem' },
    },
    MuiMenuItem: {
      root: { fontSize: '0.9rem' },
    },
  },
}

export default defaultTheme
