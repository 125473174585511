import { SuccessToast, ValidationResult } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ACCOUNTS } from 'src/presentation/routes'
import { FileTypeEnum } from 'src/utils/constants'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import { useMutation } from '@tanstack/react-query'
import { TransactionFileData } from 'src/domain/models/transaction'
import { profileCountry } from 'src/utils/user'
import { transformPropertiesToLowercase } from 'src/utils/file'
import { getStandardUri } from 'src/utils/common'

interface FileValidationParams {
  fileOperations: IFileGetOperations
  transactionPostOperations: ITransactionPostOperations
}

const TransactionFileValidation: React.FC<FileValidationParams> = ({
  fileOperations,
  transactionPostOperations,
}: FileValidationParams) => {
  const location = useLocation()
  const history = useHistory()
  const [processmentErrors, setProcessmentErrors] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  window.localStorage.removeItem('filesFilterStorage')
  window.localStorage.removeItem('accountsFilterStorage')
  window.localStorage.removeItem('accountsFiltered')

  const mutationProcessTransactionFile = useMutation({
    mutationFn: async (params: any) => {
      return transactionPostOperations.processTransactionFile(
        params.businessId,
        params.transactionFileData,
        params.file
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) setProcessmentErrors(response.data)
      else {
        history.push(getStandardUri(ACCOUNTS))
        SuccessToast('File successfully submitted')
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const stateData: any = location.state

  if (
    !stateData ||
    !stateData.hasOwnProperty('fileData') ||
    !stateData.hasOwnProperty('fileUploaded') ||
    !stateData.hasOwnProperty('businessId')
  ) {
    history.push(getStandardUri(ACCOUNTS))
    return <></>
  }

  const fileMap = fileOperations.getFileMap(FileTypeEnum.TransactionFile)
  const { fileData, fileUploaded, businessId } = stateData

  const mapToTransactionFileObject = (input: any): TransactionFileData => {
    const dataRow: TransactionFileData = {
      rowIndex: input.rowindex,
      eCAID: input.ecaid,
      lender: input.lender,
      loanid: input.loanid,
      paymentDatetime: input.paymentdatetime,
      paymentSequence: input.paymentsequence,
      totalAmount: input.totalamount,
      principalAmount: input.principalamount,
      interestAmount: input.interestamount,
      lateFeesAmount: input.latefeesamount,
      courtFeesAmount: input.courtfeesamount,
      otherFeesAmount: input.otherfeesamount,
      paymentMethod: input.paymentmethod,
      type: input.type,
    }

    return dataRow
  }

  const onSubmitFile = () => {
    setIsFetching(true)
    const transactionFileData: TransactionFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      transactionFileData.push(mapToTransactionFileObject(loweredCaseObject))
    })

    const params = {
      businessId: businessId,
      transactionFileData,
      file: fileUploaded,
    }

    mutationProcessTransactionFile.mutate(params)
  }

  return (
    <ValidationResult
      fileMap={fileMap}
      fileData={fileData}
      fileOptions={{
        validateTotalAmount: true,
        formatDate: profileCountry(),
      }}
      processmentErrors={processmentErrors}
      isFetchingProceed={isFetching}
      onClickCancelButton={() => history.push(getStandardUri(ACCOUNTS))}
      onClickProceedButton={onSubmitFile}
    />
  )
}

export default TransactionFileValidation
