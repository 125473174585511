import { ErrorToast, SuccessToast, WarningToast } from 'everchain-uilibrary'

export const useMessageSnackbar = () => {
  const showWarningMessage = (message: string) => {
    WarningToast(message)
  }

  const showErrorMessage = (message: string) => {
    ErrorToast(message)
  }

  const showSuccessMessage = (message: string) => {
    SuccessToast(message)
  }

  return { showWarningMessage, showErrorMessage, showSuccessMessage }
}
