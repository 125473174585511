/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Box, Typography } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import Creditors from './components/Creditors'
import Vendors from './components/Vendors'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import LoadActions from './components/LoadActions'
import {
  Button,
  DataTable,
  DataTableState,
  SwitchType,
  headerActions,
  renderCellTableActions,
  renderDownloadInfo,
  renderDate,
  renderDateTime,
  renderCurrency,
  Group,
  Flex,
  Content,
} from 'everchain-uilibrary'
import FilesFilters from './components/FileTypesFilters'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import DateAccountFilter from './components/DateAccountFilters'
import DateFileFilter from './components/DateFileFilters'
import { dateThreeMonthsPast } from 'src/utils/date'
import { Account } from 'src/domain/models/accounts'
import { DownloadFileUri } from 'src/utils/helper'
import { useHistory } from 'react-router-dom'
import { ACCOUNT_DETAIL } from 'src/presentation/routes'

import { profileCountry, useIsMultipleBussines } from 'src/utils/user'
import {
  useGetUserBusinessType,
  setBusinessType,
} from 'src/context/UserContext'
import { AuthContext } from 'src/context/AuthenticationContext'
import { FileData } from 'src/domain/models/file'
import { getStandardUri } from 'src/utils/common'

interface AccountsParams {
  accountOperations?: IAccountGetOperations
  businessOperations?: IBusinessGetOperations
  fileOperations?: IFileGetOperations
}

export interface FormFilter {
  creditorId: string | undefined
  vendorId: string | undefined
  fileType: number | undefined
  dateAccountFrom?: Date
  dateAccountTo?: Date
  dateFileFrom?: Date
  dateFileTo?: Date
}

const Accounts: React.FC<AccountsParams> = ({
  businessOperations,
  accountOperations,
  fileOperations,
}) => {
  const getPersistedFileFilterData = () => {
    if (window.localStorage.getItem('filesFilterStorage') !== null) {
      return JSON.parse(window.localStorage.getItem('filesFilterStorage') || '')
    }
  }

  const getPersistedAccountFilterData = () => {
    if (window.localStorage.getItem('accountsFilterStorage') !== null) {
      return JSON.parse(
        window.localStorage.getItem('accountsFilterStorage') ?? ''
      )
    }
  }

  const getPersistedAccountData = () => {
    if (window.localStorage.getItem('accountsFiltered') !== null) {
      return JSON.parse(window.localStorage.getItem('accountsFiltered') ?? '')
    }
  }

  const [switchChecked, setSwitchChecked] = useState<any>(false)
  const isMultipleBussines = useIsMultipleBussines()
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [totalFiles, setTotalFiles] = useState<number>(0)
  const [totalAccounts, setTotalAccounts] = useState<number>(
    getPersistedAccountData()?.totalCount | 0
  )
  const [files, setFilesData] = useState<FileData[]>()
  const [accounts, setAccounts] = useState<Account[] | undefined>(
    getPersistedAccountData()?.accounts || []
  )
  const [form, setForm] = useState<FormFilter | undefined>(
    switchChecked
      ? getPersistedFileFilterData() || ''
      : getPersistedAccountFilterData() || ''
  )
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const history = useHistory()
  const { isVendor, isCreditor, dispatch } = useGetUserBusinessType()

  const handleSwitchChange = (isChecked: boolean) => {
    const newSelectedType = isChecked ? 'vendor' : 'creditor'
    const setBusinessTypeAction = setBusinessType(dispatch)
    setBusinessTypeAction(newSelectedType)
    setFilesData([])
    setAccounts([])
    setForm((prevObj: any) => {
      return { ...prevObj, fileType: undefined }
    })
    window.localStorage.removeItem('accountsFilterStorage')
    window.localStorage.removeItem('filesFilterStorage')
  }

  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const fetchFiles = async (gridParams: DataTableState) => {
    try {
      setLoadingData(true)

      const params = {
        gridParams: gridParams,
        creditorId: form?.creditorId,
        vendorId: form?.vendorId,
        dateFrom: form?.dateFileFrom ?? dateThreeMonthsPast().toISOString(),
        dateTo: form?.dateFileTo ?? new Date().toISOString(),
        fileType: form?.fileType,
      }

      const dataResponse = await fileOperations?.getFileData(
        params.gridParams,
        params.creditorId,
        params.vendorId,
        params.dateFrom,
        params.dateTo,
        params.fileType
      )
      setFilesData(dataResponse?.data)
      setTotalFiles(dataResponse?.totalCount ?? 0)
      setLoadingData(false)
    } catch (err) {
      console.log(err)
      setLoadingData(false)
    }
  }

  const fetchAccounts = async (gridParams: DataTableState) => {
    try {
      setLoadingData(true)
      const dataResponse = await accountOperations?.getAccounts(
        gridParams,
        form?.creditorId,
        form?.vendorId,
        form?.dateAccountFrom ?? dateThreeMonthsPast().toISOString(),
        form?.dateAccountTo ?? new Date().toISOString()
      )
      setAccounts(dataResponse?.data)
      setTotalAccounts(dataResponse?.totalCount ?? 0)
      setLoadingData(false)
      window.localStorage.setItem(
        'accountsFiltered',
        JSON.stringify({
          accounts: dataResponse?.data,
          totalCount: dataResponse?.totalCount,
        })
      )
    } catch (err) {
      console.log(err)
      setLoadingData(false)
    }
  }

  const GridAccountsColumns = () => {
    return [
      {
        field: 'lenderLoanId',
        title: 'Loan ID',
        show: true,
        width: 100,
      },
      {
        field: 'firstName',
        title: 'First Name',
        width: 150,
        show: !isInternal,
      },
      {
        field: 'lastName',
        title: 'Last Name',
        width: 150,
        show: !isInternal,
      },
      { field: 'lender', title: 'Lender', show: true, width: 150 },
      { field: 'status', title: 'Status', show: true, width: 100 },
      {
        field: 'originalLoanAmount',
        title: 'Orig. Amt.',
        show: true,
        width: 110,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'principalBalance',
        title: 'Principal',
        show: true,
        width: 110,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'interestBalance',
        title: 'Interest',
        show: true,
        width: 100,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'otherFeesBalances',
        title: 'Fee',
        show: true,
        width: 95,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'lastPaymentAmount',
        title: 'Amount',
        show: true,
        width: 100,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'totalBalance',
        title: 'Total Balance',
        show: true,
        width: 135,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'originalDate',
        title: 'Origination Date',
        show: true,
        width: 150,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'defaultDate',
        title: 'Default Date',
        show: true,
        width: 130,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'lastPaymentDate',
        title: 'Last Payment Date',
        show: true,
        width: 165,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'writeOffDate',
        title: 'C/O Date',
        show: true,
        width: 110,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'ecaid',
        title: 'ECAID',
        show: true,
        width: 250,
      },
    ]
  }

  const renderDownloadInfoColumns = () => {
    return [
      { field: 'userName', title: 'User Name', width: 75 },
      {
        field: 'downloadedAt',
        title: 'Downloaded At',
        width: 75,
        render: renderDateTime,
      },
    ]
  }

  const GridFileColumns = () => {
    return [
      { field: 'id', title: 'ID', show: false, width: 150 },
      { field: 'fileName', title: 'File Name', show: true, width: 100 },
      {
        field: 'creditor',
        title: 'Creditor',
        show: true,
        width: 100,
        render: (props: any) => {
          return (
            <td>{props.dataItem.creditor ? props.dataItem.creditor : 'N/A'}</td>
          )
        },
      },
      {
        field: 'vendor',
        title: 'Vendor',
        show: true,
        width: 100,
        render: (props: any) => {
          return (
            <td>{props.dataItem.vendor ? props.dataItem.vendor : 'N/A'}</td>
          )
        },
      },
      { field: 'fileType', title: 'File Type', show: true, width: 100 },
      {
        field: 'dateCreated',
        title: 'Date Created',
        show: true,
        width: 100,
        render: renderDateTime,
        filter: 'date',
      },
      { field: 'strategyUsed', title: 'Strategy Used', show: true, width: 100 },
      { field: 'status', title: 'Status', show: true, width: 80 },
      {
        field: 'downloadCount',
        title: 'Download Count',
        show: true,
        width: 100,
        render: (props: any) =>
          renderDownloadInfo(
            props.dataItem.downloadHistory.historyDetails,
            renderDownloadInfoColumns(),
            props.dataItem.downloadHistory.count,
            150
          ),
        filter: 'numeric',
      },
      {
        title: 'Actions',
        render: (props: any) =>
          renderCellTableActions(props, actionsMenu(props)),
        headerCell: headerActions,
        show: true,
        width: 30,
      },
    ]
  }

  const actionsMenu = (props: any) => [
    {
      name: 'Download',
      onClick: () => {
        console.log(props.dataItem)
        fileOperations
          ?.getFileUri(props.dataItem.id)
          .then((response: any) => {
            DownloadFileUri(response)
            if (switchChecked) fetchFiles(gridState)
          })
          .catch((error) => {
            console.log(error)
          })
      },
      loading: false,
    },
    {
      name: 'Delete',
      onClick: (tableCell: any) => {},
      loading: false,
    },
  ]

  const handleCheckSwitch = (value: any) => {
    setFilesData([])
    setAccounts([])
    setSwitchChecked(value)
  }

  const handleCreditorsUpdate = (creditor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }

  const handleVendorsUpdate = (vendor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, vendorId: vendor }
    })
  }

  const handleFileTypeUpdate = (fileTypeValue: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, fileType: fileTypeValue }
    })
  }

  const onDateFromAccountFilterUpdate = (dateAccountFrom: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateAccountFrom: dateAccountFrom.toISOString() }
    })
  }

  const onDateToAccountFilterUpdate = (dateAccountTo: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateAccountTo: dateAccountTo.toISOString() }
    })
  }

  const onDateFromFileFilterUpdate = (dateFileFrom: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateFileFrom: dateFileFrom.toISOString() }
    })
  }

  const onDateToFileFilterUpdate = (dateFileTo: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateFileTo: dateFileTo.toISOString() }
    })
  }

  const getTitleName = () => {
    return switchChecked ? 'Files' : 'Accounts'
  }

  const getColumns = () => {
    return switchChecked ? GridFileColumns() : GridAccountsColumns()
  }

  const handleFilter = () => {
    if (switchChecked) {
      if (form)
        window.localStorage.setItem('filesFilterStorage', JSON.stringify(form))
      fetchFiles(gridState)
    } else {
      if (form)
        window.localStorage.setItem(
          'accountsFilterStorage',
          JSON.stringify(form)
        )
      fetchAccounts(gridState)
    }
  }

  const handleRowClick = (event: any) => {
    history.push(getStandardUri(`${ACCOUNT_DETAIL}/${event.dataItem.ecaid}`))
  }

  return (
    <Content id="accounts">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '5vh',
        }}
      >
        <Typography variant="subtitle1">{getTitleName()}</Typography>
        <Box display="flex" gridGap={10}>
          <LoadActions fileOperations={fileOperations}></LoadActions>
          <>
            {isMultipleBussines && (
              <SwitchType
                id="isVendorCheck"
                checked={isVendor}
                onChange={(e: { target: { checked: boolean } }) => {
                  handleCreditorsUpdate(null)
                  handleVendorsUpdate(null)
                  handleFileTypeUpdate(undefined)
                  handleSwitchChange(e.target.checked)
                }}
                variant="primary"
                primary="Creditor"
                secondary="Vendor"
              ></SwitchType>
            )}
          </>
          <SwitchType
            id="fileTypeCheck"
            checked={switchChecked}
            onChange={(e: { target: { checked: any } }) =>
              handleCheckSwitch(e.target.checked)
            }
            variant="primary"
            primary="Accounts"
            secondary="Files"
          ></SwitchType>
        </Box>
      </div>
      <Flex justifyContent={'space-between'}>
        <Group>
          <>
            {(isInternal || isCreditor) && (
              <Creditors
                businessOperations={businessOperations}
                onCreditorsUpdate={handleCreditorsUpdate}
                selectedCreditor={getPersistedAccountFilterData()?.creditorId}
              />
            )}
            {(isInternal || isVendor) && (
              <Vendors
                businessOperations={businessOperations}
                onVendorUpdate={handleVendorsUpdate}
                selectedVendor={getPersistedAccountFilterData()?.vendorId}
              />
            )}
          </>

          {switchChecked && (
            <FilesFilters
              fileOperations={fileOperations}
              onFileTypeUpdate={handleFileTypeUpdate}
              selectedFileType={getPersistedFileFilterData()?.fileType}
              form={form}
            />
          )}
          {switchChecked ? (
            <DateFileFilter
              onDateFromFilterUpdate={onDateFromFileFilterUpdate}
              onDateToFilterUpdate={onDateToFileFilterUpdate}
              selectedFromDate={getPersistedFileFilterData()?.dateFileFrom}
              selectedToDate={getPersistedFileFilterData()?.dateFileTo}
            />
          ) : (
            <DateAccountFilter
              onDateFromFilterUpdate={onDateFromAccountFilterUpdate}
              onDateToFilterUpdate={onDateToAccountFilterUpdate}
              selectedFromDate={
                getPersistedAccountFilterData()?.dateAccountFrom
              }
              selectedToDate={getPersistedAccountFilterData()?.dateAccountTo}
            />
          )}
        </Group>
        <Button
          useRipple
          width={80}
          height={40}
          onClick={handleFilter}
          isLoading={loadingData}
          disabled={
            !isInternal &&
            ((isCreditor && form?.creditorId == null) ||
              (isVendor && form?.vendorId == null))
          }
        >
          Filter
        </Button>
      </Flex>

      <Box mt={3}>
        {switchChecked ? (
          <DataTable
            isLoading={loadingData}
            height="100%"
            maxHeight="100%"
            gridColumns={getColumns() || []}
            gridState={gridState}
            data={files}
            pageable={true}
            total={totalFiles}
            onDataStateChange={(e: { dataState: any }) => {
              setGridState(e.dataState)
              if (files) {
                fetchFiles(e.dataState)
              }
            }}
          />
        ) : (
          <DataTable
            sortable={true}
            useFilterMenu={true}
            isLoading={loadingData}
            height="100%"
            maxHeight="100%"
            gridColumns={getColumns() || []}
            gridState={gridState}
            data={accounts}
            pageable={true}
            total={totalAccounts}
            onRowClick={handleRowClick}
            onDataStateChange={(e: { dataState: any }) => {
              setGridState(e.dataState)
              if (accounts) {
                fetchAccounts(e.dataState)
              }
            }}
          />
        )}
      </Box>
    </Content>
  )
}

export default Accounts
