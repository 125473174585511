import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    html {
      font-size: 0.9rem;
    }
    body {          
      line-height: 1rem;
      text-transform: none;
      font-family: 'Roboto', sans-serif;
      font-size: 0.9rem;
    }
    #root {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    @media(min-width: 601) {
      html {
        font-size: 0.9rem;
      }
      .MuiTypography-h6 {
        font-size: 1rem;
      }
    }
    @media(min-width: 768px) {
      html {
        font-size: 0.9rem;
      }
      .MuiTypography-h6 {
        font-size: 1rem;
      }
    }
    @media(min-width: 1281px) {
      html {
        font-size: 0.9rem;
      }
      .MuiTypography-h6 {
        font-size: 1rem;
      }
    }
    th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall {
      padding: 8px 6px 8px 6px;
    }    
    .MuiTableCell-sizeSmall {
      padding: 8px 7px 8px 7px;
    }
    .acceptBidButton:hover
    {
      color: #002E3A;
    }
    .MuiPaginationItem-textPrimary.Mui-selected {
      color: #ffffff;
      background-color: #82C2E1;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 48px
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px 0px
    }
    .MuiAccordionSummary-content {
      color: #002E3A;
      margin: 0px 0px
    }
    .MuiAccordionSummary-content .MuiTypography-root {
      color: #002E3A;
    }
    .MuiMenu-list {
      max-height: 292px;
    }
    .MuiTypography-root .MuiListItemText-primary .MuiTypography-body2 .MuiTypography-displayBlock {
      color: #002E3A;
    }
    .logo-sidebar {
    margin-right: 10px;
    }
    .MuiListItemIcon-root {
      color: #002E3A;
    }
    .MuiTypography-colorTextSecondary {
       color: #002E3A;
    }
    .MuiHeaderAlignRight {
      text-align: right;
    }
    .MuiHeaderAlignCenter {
      text-align: center;
    }
    .MuiTableCell-sizeSmall:last-child {
    padding-right: 8px;
    }
    tr.MuiTableRow-root:nth-of-type(even){
      background-color: #ffffff;
    }
`
