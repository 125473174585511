import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  Colors,
  DataTable,
  DataTableState,
  ErrorToast,
  ModalDialog,
  SuccessToast,
  Tooltip,
  Typography,
  headerActions,
  renderCellTableActions,
  renderCurrency,
  renderDate,
  renderElementWithTooltip,
} from 'everchain-uilibrary'
import React, { useContext, useEffect, useState } from 'react'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import { profileCountry } from 'src/utils/user'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { AuthContext } from 'src/context/AuthenticationContext'
import { refetchQueriesWrapper } from 'src/infra/reactQuery'

interface AccountActivityProps {
  eCAID: string
  accountOperations: IAccountGetOperations
  isCardLoading: boolean
  transactionPostOperations: ITransactionPostOperations
  businessIds: any
}

const AccountActivity: React.FC<AccountActivityProps> = ({
  eCAID,
  accountOperations,
  isCardLoading,
  transactionPostOperations,
  businessIds,
}: AccountActivityProps) => {
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [form, setForm] = useState<any>({
    accountActivity: [],
    totalAccountActivity: 0,
    isLoading: false,
  })
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>()
  const [selectedPaymentId, setSelectedPaymentId] = useState<any>()
  const [bouncedPaymentProcessing, setBouncedPaymentProcessing] =
    useState<boolean>()
  const queryClient = useQueryClient()

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 100,
    filter: undefined,
    sort: undefined,
  })

  const fetchActivity = async (gridParams: DataTableState) => {
    try {
      setForm((prevObj: any) => {
        return { ...prevObj, isLoading: true }
      })

      const dataResponse = await accountOperations?.getAccountActivity(
        gridParams,
        eCAID
      )

      setForm((prevObj: any) => {
        return {
          ...prevObj,
          accountActivity: dataResponse?.data,
          totalAccountActivity: dataResponse?.totalCount ?? 0,
          isLoading: false,
        }
      })
    } catch (err) {
      console.log(err)
      setForm((prevObj: any) => {
        return { ...prevObj, isLoading: false }
      })
    }
  }

  const mutationMarkPaymentAsBounced = useMutation({
    mutationFn: async () => {
      const createdBy = businessIds.creditorId ? 'Seller' : 'Agency'
      return transactionPostOperations.reportBouncedTransaction(
        selectedPaymentId,
        createdBy
      )
    },
    onSuccess: async (response: any) => {
      if (response.data) {
        setBouncedPaymentProcessing(false)
        setOpenConfirmationModal(false)
        SuccessToast('Payment returned successfully')
        refetchQueriesWrapper(['getAccountDetails'], queryClient)
        fetchActivity(gridState)
      } else {
        setBouncedPaymentProcessing(false)
        setOpenConfirmationModal(false)
        ErrorToast('There was an error while flagging the payment')
      }
    },
    onError: async (response: any) => {
      setBouncedPaymentProcessing(false)
      setOpenConfirmationModal(false)
    },
  })

  const renderIconButton = (props: any) => {
    return props.dataItem.bouncedTransaction ? (
      <div style={{ display: 'flex' }} data-test-id="bounced-payment">
        {props.dataItem.recordType}
        <Tooltip title="Payment bounced">
          <ErrorOutlineIcon color="error" style={{ marginLeft: 5 }} />
        </Tooltip>
      </div>
    ) : (
      <>{props.dataItem.recordType}</>
    )
  }
  const actionsMenu = (props: any) => {
    return !props.dataItem.bouncedTransaction
      ? [
          {
            name: 'Return payment',
            onClick: () => {
              setSelectedPaymentId(props.dataItem?.transactionId)
              setOpenConfirmationModal(true)
            },
            show: true,
            loading: false,
          },
        ]
      : [
          {
            name: 'Payment already returned',
            show: true,
            onClick: () => {},
          },
        ]
  }

  const columns = [
    {
      field: 'recordType',
      title: 'Record Type',
      show: true,
      width: 250,
      render: (props: any) => renderElementWithTooltip(renderIconButton(props)),
    },
    { field: 'createdBy', title: 'Created By', show: true, width: 100 },
    {
      field: 'totalAmount',
      title: 'Total Amount',
      show: true,
      width: 100,
      render: (props: any) => renderCurrency(props, profileCountry()),
    },
    {
      field: 'createdDate',
      title: 'Created Date',
      show: true,
      width: 100,
      render: renderDate,
    },
    {
      title: 'Actions',
      render: (props: any) => renderCellTableActions(props, actionsMenu(props)),
      headerCell: headerActions,
      show: !isInternal,
      width: 30,
    },
  ]

  const handleMarkPaymentAsBounced = async () => {
    setBouncedPaymentProcessing(true)
    await mutationMarkPaymentAsBounced.mutateAsync()
  }

  useEffect(() => {
    if (!isCardLoading) {
      fetchActivity(gridState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardLoading])

  return (
    <div data-test-id="accountActivity">
      <DataTable
        isLoading={form.isLoading || isCardLoading}
        height="100%"
        maxHeight="100%"
        gridColumns={columns || []}
        gridState={gridState}
        data={form.accountActivity}
        pageable={true}
        total={form.totalAccountActivity}
        onDataStateChange={(e) => {
          setGridState(e.dataState)
          if (form.chainOfPlacements) {
            fetchActivity(e.dataState)
          }
        }}
      />
      <ModalDialog
        header="Confirmation"
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        isLoading={bouncedPaymentProcessing}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        onContinue={() => {
          handleMarkPaymentAsBounced()
        }}
      >
        <Typography
          color={Colors.primary}
          variant="caption"
          style={{ fontSize: 14 }}
          isLoading={bouncedPaymentProcessing}
        >
          Do you confirm that you want to return this payment?
        </Typography>
      </ModalDialog>
    </div>
  )
}

export default AccountActivity
