import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import {
  Account,
  AccountActivity,
  AccountChainOfPlacement,
  AccountDetailsData,
} from 'src/domain/models/accounts'
import {
  AccountsGetAccountActivity,
  AccountsGetAccountChainOfPlacement,
  AccountsGetAccountDetails,
  AccountsGetAccounts,
} from './urls'
import { DataResponse } from 'src/domain/models/common'
import { DataTableState } from 'everchain-uilibrary'

export class AccountGetData implements IAccountGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getAccountDetails(
    eCAID: string,
    businessIds: string[]
  ): Promise<AccountDetailsData> {
    const businessParam = businessIds
      ? `&businessIds=${JSON.stringify(businessIds)} `
      : ''
    const response = await this.httpClient.get(
      `${AccountsGetAccountDetails}?eCAID=${eCAID}${businessParam}`
    )
    return response.data
  }

  async getAccounts(
    pagination: DataTableState,
    creditorId?: string,
    vendorId?: string,
    dateFrom?: Date,
    dateTo?: Date
  ): Promise<DataResponse<Account>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const creditorFilter = creditorId ? `&creditorId= ${creditorId} ` : ''
    const vendorFilter = vendorId ? `&vendorId= ${vendorId} ` : ''
    const dateFromFilter = dateFrom ? `&dateFrom= ${dateFrom} ` : ''
    const dateToFilter = dateTo ? `&dateTo= ${dateTo} ` : ''
    const response = await this.httpClient.get(
      `${AccountsGetAccounts}?pagination=${encodedData}${vendorFilter}${creditorFilter}${dateFromFilter}${dateToFilter}`
    )
    return response.data
  }

  async getAccountChainOfPlacement(
    pagination: DataTableState,
    eCAID: string,
    isCreditor: Boolean,
    businessId?: string
  ): Promise<DataResponse<AccountChainOfPlacement>> {
    const businessParam = businessId ? `&businessId=${businessId} ` : ''
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${AccountsGetAccountChainOfPlacement}?pagination=${encodedData}&eCAID=${eCAID}${businessParam}&isCreditor=${isCreditor}`
    )
    return response.data
  }

  async getAccountActivity(
    pagination: DataTableState,
    eCAID: string
  ): Promise<DataResponse<AccountActivity>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${AccountsGetAccountActivity}?pagination=${encodedData}&eCAID=${eCAID}`
    )
    return response.data
  }
}
