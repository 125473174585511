import { ErrorToast, SuccessToast, ValidationResult } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ACCOUNTS } from 'src/presentation/routes'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { RecallAccountFileData } from 'src/domain/models/accounts'
import { useMutation } from '@tanstack/react-query'
import { FileTypeEnum } from 'src/utils/constants'
import { profileCountry } from 'src/utils/user'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { useGetUserBusinessType } from 'src/context/UserContext'
import { transformPropertiesToLowercase } from 'src/utils/file'
import { getStandardUri } from 'src/utils/common'

interface FileValidationParams {
  fileOperations: IFileGetOperations
  accountPostOperations: IAccountPostOperations
}

const RecallFileValidation: React.FC<FileValidationParams> = ({
  fileOperations,
  accountPostOperations,
}: FileValidationParams) => {
  const location = useLocation()
  const history = useHistory()
  const [processmentErrors, setProcessmentErrors] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const { isVendor, isCreditor } = useGetUserBusinessType()
  window.localStorage.removeItem('filesFilterStorage')
  window.localStorage.removeItem('accountsFilterStorage')
  window.localStorage.removeItem('accountsFiltered')

  const mutationProcessRecallAccountFile = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processRecallAccountFile(
        params.creditorId,
        params.recallAccountFileData,
        params.file
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) setProcessmentErrors(response.data)
      else {
        history.push(getStandardUri(ACCOUNTS))
        SuccessToast('File successfully submitted')
      }
    },
  })

  const mutationProcessReturnAccountFile = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processReturnAccountFile(
        params.vendorId,
        params.recallAccountFileData,
        params.file
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) setProcessmentErrors(response.data)
      else {
        history.push(getStandardUri(ACCOUNTS))
        SuccessToast('File successfully submitted')
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const stateData: any = location.state

  if (
    !stateData ||
    !stateData.hasOwnProperty('fileData') ||
    !stateData.hasOwnProperty('fileUploaded') ||
    !stateData.hasOwnProperty('businessSeleted')
  ) {
    history.push(getStandardUri(ACCOUNTS))
    return <></>
  }

  const fileMap = fileOperations.getFileMap(FileTypeEnum.RecallAccountFile)
  const { fileData, fileUploaded, businessSeleted } = stateData

  const mapToRecallAccountFileObject = (input: any): RecallAccountFileData => {
    const dataRow: RecallAccountFileData = {
      rowIndex: input.rowindex,
      eCAID: input.ecaid,
      lender: input.lender,
      lenderLoanId: input.loanid,
    }

    return dataRow
  }

  const onSubmitFile = () => {
    setIsFetching(true)
    const recallAccountFileData: RecallAccountFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      recallAccountFileData.push(
        mapToRecallAccountFileObject(loweredCaseObject)
      )
    })

    if (isCreditor) {
      const params = {
        creditorId: businessSeleted,
        recallAccountFileData,
        file: fileUploaded,
      }

      mutationProcessRecallAccountFile.mutate(params)
    } else if (isVendor) {
      const params = {
        vendorId: businessSeleted,
        recallAccountFileData,
        file: fileUploaded,
      }

      mutationProcessReturnAccountFile.mutate(params)
    } else {
      ErrorToast('Only creditors or vendors can recall/return accounts')
    }
  }

  return (
    <ValidationResult
      fileMap={fileMap}
      fileData={fileData}
      fileOptions={{
        formatDate: profileCountry(),
      }}
      processmentErrors={processmentErrors}
      isFetchingProceed={isFetching}
      onClickCancelButton={() => history.push(getStandardUri(ACCOUNTS))}
      onClickProceedButton={onSubmitFile}
    />
  )
}

export default RecallFileValidation
