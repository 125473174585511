import { ValidationRulesDictionary } from '../validation'

export const getValidation = (
  validation: ValidationRulesDictionary | undefined,
  field: string
) => {
  if (!validation) return undefined
  else {
    if (!validation[field]) return undefined
    return validation[field].get()
  }
}
