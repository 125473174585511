import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { ISearchGetOperations } from 'src/domain/features/get/search/search'
import {
  SearchFilterTypeEnum,
  SearchDataResponse,
} from 'src/domain/models/search'
import { SearchGetSearches } from './urls'

export class SearchGetData implements ISearchGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getSearchs(
    pageNumber: number,
    pageSize: number,
    searchInput: string,
    searchType: SearchFilterTypeEnum
  ): Promise<SearchDataResponse[]> {
    const response = await this.httpClient.get(
      `${SearchGetSearches}?pageNumber=${pageNumber}&pageSize=${pageSize}&searchInput=${searchInput}&searchType=${searchType}`
    )
    return response.data
  }
}
