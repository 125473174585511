import React, { useContext } from 'react'
import { Switch } from 'react-router-dom'
import PrivatedRoute from 'src/presentation/components/PrivatedRoute'
import { MainLayout } from 'src/presentation/layouts'
import {
  ACCOUNTS,
  ACCOUNT_DETAIL,
  ACCOUNT_FILE_VALIDATION,
  STRATEGIES,
  STRATEGY,
  RECALL_ACCOUNT_FILE_VALIDATION,
  TRANSACTION_FILE_VALIDATION,
  SEARCH,
  BUSINESS_SETTINGS,
} from 'src/presentation/routes'
import { Permissions } from 'src/domain/models/permissions'
import makeAccounts from 'src/main/factories/pages/Accounts'
import makeAccountDetail from 'src/main/factories/pages/Accounts/AccountsDetail'
import makeNotFound from 'src/main/factories/pages/NotFound'
import makeStrategyForm from 'src/main/factories/pages/Strategy/Form'
import makeStrategyList from 'src/main/factories/pages/Strategy/List'
import { AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import { buildAbilityFor } from 'src/configs/ability'
import { PermissionCodeAccess } from 'src/utils/constants'
import makeAccessDenied from 'src/main/factories/pages/AccessDenied'
import makeAccountFileValidation from 'src/main/factories/pages/Accounts/FileValidation/makeAccountFileValidation'
import makeRecallFileValidation from 'src/main/factories/pages/Accounts/FileValidation/makeRecallFileValidation'
import makeTransactionFileValidation from 'src/main/factories/pages/Accounts/FileValidation/makeTransactionFileValidation'
import makeSearch from 'src/main/factories/pages/Search'
import { useGetUserBusinessType } from 'src/context/UserContext'
import makeBusinessSettings from 'src/main/factories/pages/Business/BusinessSettings'

interface AppProps {
  managePermission: Permissions[]
}
interface AbilityProviderProps {
  children: React.ReactNode
  permissionValues: Permissions[]
}

const AbilityProvider = ({
  children,
  permissionValues,
}: AbilityProviderProps) => {
  const { userPermissions } = useContext(AuthContext)
  const ability = buildAbilityFor(userPermissions.type, permissionValues)
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}
interface PermissionReduce {
  [field: string]: boolean
}
const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce =>
  permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )

const App = ({ managePermission }: AppProps) => {
  const { isVendor } = useGetUserBusinessType()
  return (
    <AbilityProvider permissionValues={managePermission}>
      <MainLayout>
        <Switch>
          <PrivatedRoute
            exact
            path={`${ACCOUNT_DETAIL}/:ECAID/:backUrl?`}
            component={makeAccountDetail}
            permissionValues={managePermission}
          ></PrivatedRoute>
          <PrivatedRoute
            exact
            path={`${STRATEGY}/:id?`}
            permissionValues={managePermission}
            component={
              !isVendor &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.Recover_CreateStrategies
              ]
                ? makeStrategyForm
                : makeAccessDenied
            }
          ></PrivatedRoute>
          <PrivatedRoute
            permissionValues={managePermission}
            exact
            path={`${STRATEGIES}`}
            component={
              !isVendor &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.Recover_CreateStrategies
              ]
                ? makeStrategyList
                : makeAccessDenied
            }
          ></PrivatedRoute>
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeAccounts}
            exact
            path={ACCOUNTS}
          ></PrivatedRoute>
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeAccountFileValidation}
            exact
            path={ACCOUNT_FILE_VALIDATION}
          ></PrivatedRoute>
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeRecallFileValidation}
            exact
            path={RECALL_ACCOUNT_FILE_VALIDATION}
          ></PrivatedRoute>
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeTransactionFileValidation}
            exact
            path={TRANSACTION_FILE_VALIDATION}
          ></PrivatedRoute>
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeSearch}
            exact
            path={`${SEARCH}/:pageNumber/:pageSize/:searchInput/:searchType`}
          ></PrivatedRoute>
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeBusinessSettings}
            exact
            path={BUSINESS_SETTINGS}
          ></PrivatedRoute>
          <PrivatedRoute
            permissionValues={managePermission}
            component={makeNotFound}
            path="*"
          ></PrivatedRoute>
        </Switch>
      </MainLayout>
    </AbilityProvider>
  )
}

export default App
