import styled from 'styled-components'
import { DialogTitle } from '@material-ui/core'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`

export const DialogTitleStyled = styled(DialogTitle)`
  background-color: ${({ theme }): string => theme.debtColors.debtTea};
`
