import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import { OldAuthContext } from 'src/context/OldAuthenticationContext'
import { ACCESSDENIED, HOME } from './routes'

import { SignIn } from '../presentation/components/Authentication/SignIn'
import { SignInCallback } from '../presentation/components/Authentication/SignInCallback'
import { SignInSilent } from '../presentation/components/Authentication/SignInSilent'
import { SignOutCallback } from '../presentation/components/Authentication/SignOutCallback'

import SwitchRouteProtect from './SwitchRouteProtect'
import AccessDenied from './pages/AccessDenied'
import { PasswordExpiringNotification } from '../presentation/components/PasswordExpiringNotification'
import { AuthProvider } from 'src/context/AuthenticationContext'

const App: React.FC = () => {
  const oldAuthContext = useContext(OldAuthContext)

  return (
    <AuthProvider {...oldAuthContext}>
      <Switch>
        <Route exact path={HOME}>
          <SignIn />
        </Route>
        <Route exact path="/signin-callback">
          <SignInCallback />
        </Route>
        <Route exact path="/signin-silent">
          <SignInSilent />
        </Route>
        <Route exact path="/signout-callback">
          <SignOutCallback />
        </Route>
        <Route exact path={ACCESSDENIED}>
          <AccessDenied />
        </Route>
        <Route>
          <PasswordExpiringNotification>
            <SwitchRouteProtect />
          </PasswordExpiringNotification>
        </Route>
      </Switch>
    </AuthProvider>
  )
}

export default App
