import {
  ValidationRules,
  ValidationRulesDictionary,
} from 'src/validation/validation'

const makeStrategyStagesValidation: ValidationRulesDictionary = {
  order: new ValidationRules().required('Description is required'),
  duration: new ValidationRules()
    .required('Duration is required')
    .min(1, 'The duration must not be equal to or less than 1')
    .max(9999, 'Duration should be equal or less than 9999'),
}
export default makeStrategyStagesValidation
