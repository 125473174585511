import React, { useState } from 'react'
import {
  Box,
  Button,
  DataTable,
  DataTableState,
  DropdownMenu,
  DropdownMenuItem,
  Flex,
  Grid,
  Icon,
  ModalDialog,
  ValidationRulesDictionary,
} from 'everchain-uilibrary'

import BusinessSettingModal from './components/BusinessSettingModal'
import { useCustomQuery } from 'src/infra/reactQuery'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IBusinessPostOperations } from 'src/domain/features/post/business/business'

interface BusinessSettingsListParams {
  businessGetOperations?: IBusinessGetOperations
  businessPostOperations?: IBusinessPostOperations
  validation?: ValidationRulesDictionary | undefined
}

const BusinessList: React.FC<BusinessSettingsListParams> = ({
  businessGetOperations,
  businessPostOperations,
  validation,
}: BusinessSettingsListParams) => {
  const ActionColumn = (props: any): React.ReactElement => {
    return (
      <td style={{ cursor: 'default' }}>
        <div style={{ textAlign: 'center' }}>
          <DropdownMenu icon={<Icon name="MoreVert" />}>
            <DropdownMenuItem
              onClick={() => {
                setSelectedBusiness(
                  data.find(
                    (x: any) => x.businessId === props.dataItem.businessId
                  )
                )
                setOpenSettingModal(true)
              }}
            >
              Business Settings
            </DropdownMenuItem>
          </DropdownMenu>
        </div>
      </td>
    )
  }
  const gridColumns: any[] = [
    {
      field: 'businessId',
      title: 'Business Id',
      width: 150,
      show: false,
    },
    {
      field: 'businessName',
      title: 'Business Name',
      show: true,
    },
    {
      field: 'sftpRootDirectory',
      title: 'Sftp Root Directory',
      show: true,
    },
    {
      field: 'sftpServiceProvider',
      title: 'Sftp Service Provider',
      show: true,
    },
    {
      field: '',
      title: 'Actions',
      width: '90px',
      render: ActionColumn,
      show: true,
    },
  ]

  const [totalDataSize, setTotalDataSize] = useState<number>(5)
  const [openSettingModal, setOpenSettingModal] = useState<boolean>(false)
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null)
  const [data, setData] = useState<any>([])
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 10,
    filter: undefined,
    sort: undefined,
  })

  const { isFetching: loading } = useCustomQuery<any>(
    ['getBusinessesSettings', gridState],
    async () => {
      await businessGetOperations
        ?.getBusinessesSettings(gridState)
        .then((result: any) => {
          setData(result.data)
          setTotalDataSize(result.total)
          return result || []
        })
    },
    { enabled: true, cacheTime: 0 }
  )

  return (
    <Box pt="15px">
      <Grid container>
        <Flex w="100%" justifyContent="end">
          <Button
            onClick={() => {
              setSelectedBusiness(null)
              setOpenSettingModal(true)
            }}
          >
            New Business Setup
          </Button>
        </Flex>
        <Grid item style={{ paddingTop: '15px', cursor: 'pointer' }}>
          <DataTable
            isLoading={loading}
            isFetching={loading}
            gridColumns={gridColumns}
            gridState={gridState}
            data={data}
            sortable
            pageable
            total={totalDataSize}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
            }}
          />
        </Grid>
      </Grid>
      <ModalDialog
        id="boxBusinessSettings"
        header="Business Settings"
        hideOkButton
        hideCancelButton
        isOpen={openSettingModal}
        onClose={() => {
          setOpenSettingModal(false)
          setSelectedBusiness(null)
        }}
        width="550px"
      >
        <BusinessSettingModal
          selectedBusiness={selectedBusiness}
          businessGetOperations={businessGetOperations}
          businessPostOperations={businessPostOperations}
          validation={validation}
          setOpenSettingModal={setOpenSettingModal}
        />
      </ModalDialog>
    </Box>
  )
}

export default BusinessList
