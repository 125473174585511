/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React from 'react'
import {
  Content,
  SearchResultDetail,
  SearchResultDetails,
  SearchResultType,
  SearchResultItem,
} from 'everchain-uilibrary'
import { useHistory, useParams } from 'react-router-dom'
import { ISearchGetOperations } from 'src/domain/features/get/search/search'
import {
  SearchDataResponse,
  getDescriptionFromSearchResultEnum,
  getRedirectionRouteSearchResultEnum,
} from 'src/domain/models/search'
import { SEARCH } from 'src/presentation/routes'
import { useCustomQuery } from 'src/infra/reactQuery'
import { getStandardUri } from 'src/utils/common'

interface SearchDetailParams {
  searchOperations: ISearchGetOperations
}

const SearchDetail: React.FC<SearchDetailParams> = ({ searchOperations }) => {
  const { pageNumber, pageSize, searchInput, searchType } = useParams<any>()
  const history = useHistory()

  const handleSearchResultDetails = (
    value?: SearchDataResponse[]
  ): SearchResultDetails[] => {
    return (
      value?.map((item, x) => ({
        resultType: getDescriptionFromSearchResultEnum(item.resultType),
        searchResult: {
          pageNumber: item.searches.pageNumber,
          pageSize: item.searches.pageSize,
          totalCount: item.searches.totalCount,
          searches: item.searches.data,
        },
      })) ?? []
    )
  }

  const checkUniqueResultAndRedirect = (data: SearchDataResponse[]) => {
    const results = data?.map((item, x) => {
      if (item?.searches?.data?.length > 0) {
        return {
          resultType: item.resultType,
          searches: item.searches.data,
        }
      }
      return null
    })

    if (results?.length === 1 && results[0]?.searches.length === 1) {
      handleOnClickItem(
        results[0].searches[0],
        getDescriptionFromSearchResultEnum(results[0].resultType),
        -2
      )
    }
  }

  const { data: accountDetailsData, isFetching } = useCustomQuery<any>(
    ['search.getSearchs', pageNumber, pageSize, searchInput, searchType],
    async () =>
      searchOperations.getSearchs(
        pageNumber,
        pageSize,
        searchInput,
        searchType
      ),
    {
      cacheTime: 0,
      onSuccess: (data) => checkUniqueResultAndRedirect(data),
    }
  )

  const searchResult: SearchResultDetails[] =
    handleSearchResultDetails(accountDetailsData)

  const handleOnClickArrow = (isBack: boolean) => {
    const pageNumberValue = isBack
      ? Number(pageNumber) - 1
      : Number(pageNumber) + 1

    history.push(
      getStandardUri(
        `${SEARCH}/${
          pageNumberValue ?? 1
        }/${pageSize}/${searchInput}/${searchType}`
      )
    )
  }

  const handleOnClickItem = (
    item: SearchResultItem,
    resultType: SearchResultType,
    backUrl?: string | number
  ) => {
    const route = getRedirectionRouteSearchResultEnum(
      item.key,
      Number(resultType.id),
      backUrl
    )

    history.push(getStandardUri(route))
  }

  return (
    <Content>
      <SearchResultDetail
        isLoading={isFetching}
        searchResultDetails={searchResult}
        onClickGoBackArrow={() => handleOnClickArrow(true)}
        onClickGoForwardArrow={() => handleOnClickArrow(false)}
        onClickItem={handleOnClickItem}
      />
    </Content>
  )
}

export default SearchDetail
