import {
  Colors,
  ErrorToast,
  ModalDialog,
  SuccessToast,
  Typography,
  ValidationResult,
} from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ACCOUNTS } from 'src/presentation/routes'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { AccountFileData, OverloadedVendor } from 'src/domain/models/accounts'
import { useMutation } from '@tanstack/react-query'
import { FileTypeEnum } from 'src/utils/constants'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { profileCountry } from 'src/utils/user'
import { transformPropertiesToLowercase } from 'src/utils/file'
import { getStandardUri } from 'src/utils/common'

interface FileValidationParams {
  fileOperations: IFileGetOperations
  accountPostOperations: IAccountPostOperations
}

const AccountFileValidation: React.FC<FileValidationParams> = ({
  fileOperations,
  accountPostOperations,
}: FileValidationParams) => {
  const location = useLocation()
  const history = useHistory()
  const [processmentErrors, setProcessmentErrors] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [isCheckVendorCapacity, setIsCheckVendorCapacity] =
    useState<boolean>(false)
  const [vendorOverloadedCapacity, setVendorOverloadedCapacity] = useState<
    OverloadedVendor[]
  >([])
  window.localStorage.removeItem('filesFilterStorage')
  window.localStorage.removeItem('accountsFilterStorage')
  window.localStorage.removeItem('accountsFiltered')

  const mutationProcessAccountFile = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processAccountFile(
        params.creditorId,
        params.strategyId,
        params.accountFileData,
        params.file,
        params.overloadedVendors
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) setProcessmentErrors(response.data)
      else {
        history.push(getStandardUri(ACCOUNTS))
        SuccessToast('File successfully submitted')
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const mutationcheckVendorAccountLoad = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.checkVendorAccountLoad(
        params.strategyId,
        params.accountFileData
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) {
        setVendorOverloadedCapacity(response.data)
        setIsCheckVendorCapacity(true)
      } else {
        handleConfirmProcessAccountFile()
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const stateData: any = location.state

  if (
    !stateData ||
    !stateData.hasOwnProperty('fileData') ||
    !stateData.hasOwnProperty('creditorId') ||
    !stateData.hasOwnProperty('strategyId') ||
    !stateData.hasOwnProperty('fileUploaded')
  ) {
    history.push(getStandardUri(ACCOUNTS))
    ErrorToast('Error')
    return <></>
  }

  const fileMap = fileOperations.getFileMap(FileTypeEnum.AccountFile)
  const { fileData, creditorId, strategyId, fileUploaded } = stateData

  const mapToAccountFileObject = (input: any): AccountFileData => {
    const dataRow: AccountFileData = {
      rowIndex: input.rowindex,
      lender: input.lender,
      loanid: input.loanid,
      originalLoanAmount: input.fundamount,
      principalBalance: input.principalbalance,
      interestBalance: input.interestbalance,
      otherFeesBalances: input.feebalance,
      originalDate: input.defaultdate,
      writeOffDate: input.writeoffdate,
      defaultDate: input.defaultdate,
      firstDelinquencyDate: input.firstdelinquencydate,
      lastPaymentDate: input.lastpaymentdate,
      lastPaymentAmount: input.lastpaymentamount,
      dOB: input.dob,
      firstName: input.firstname,
      lastName: input.lastname,
      mI: input.mi,
      sSN: input.ssn,
      address1: input.address1,
      address2: input.address2,
      city: input.city,
      state: input.state,
      zip: input.zip,
      country: input.country,
    }

    return dataRow
  }

  const onSubmitFile = () => {
    setIsFetching(true)
    const accountFileData: AccountFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      accountFileData.push(mapToAccountFileObject(loweredCaseObject))
    })

    const params = {
      strategyId: strategyId,
      accountFileData,
    }
    mutationcheckVendorAccountLoad.mutate(params)
  }

  const handleConfirmProcessAccountFile = () => {
    setIsFetching(true)
    setIsCheckVendorCapacity(false)
    const accountFileData: AccountFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      accountFileData.push(mapToAccountFileObject(loweredCaseObject))
    })

    const overloadedVendors = vendorOverloadedCapacity.map((x) => x.vendorName)
    const params = {
      creditorId,
      strategyId: strategyId,
      accountFileData,
      file: fileUploaded,
      overloadedVendors: overloadedVendors,
    }
    mutationProcessAccountFile.mutate(params)
  }

  return (
    <>
      <ValidationResult
        fileMap={fileMap}
        fileOptions={{
          validateTotalBalance: true,
          formatDate: profileCountry(),
        }}
        fileData={fileData}
        processmentErrors={processmentErrors}
        isFetchingProceed={isFetching}
        onClickCancelButton={() => history.push(getStandardUri(ACCOUNTS))}
        onClickProceedButton={onSubmitFile}
      />

      <ModalDialog
        header="Confirm Account Placement"
        isOpen={isCheckVendorCapacity}
        buttonCancelText="Cancel"
        buttonOkText="Confirm"
        onClose={() => setIsCheckVendorCapacity(false)}
        onContinue={handleConfirmProcessAccountFile}
        isLoading={isFetching}
      >
        <Typography
          color={Colors.primary}
          variant="caption"
          style={{ fontSize: 14 }}
        >
          {vendorOverloadedCapacity.map((x, index) => (
            <p key={index}>
              Proceeding with current strategy will make agency {x.vendorName}{' '}
              exceed 90% of their capacity of {x.maxAccountCapacity} accounts.
            </p>
          ))}
        </Typography>
      </ModalDialog>
    </>
  )
}

export default AccountFileValidation
