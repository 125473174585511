import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
} from 'react'

import AuthService from 'src/configs/AuthService'
import { updateUserType } from 'src/data/features/post/permission'
import {
  CLIENT_INFO,
  INTERNAL,
  SELLER,
  IS_RESELLER,
  TENANT_TYPE,
  BUYER,
  BUSINESS_INFO,
} from 'src/utils/constants'
import { IAuthContext, Permissions } from './Interfaces'
import { Business, UserPermissions } from './AuthenticationContext'

interface AuthProviderProps {
  children: React.ReactNode
}

export const OldAuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  userPermissions: {
    type: 'Buyer',
    isReseller: false,
  },
  profileBusiness: [],
  profileClient: [],
  managePermissions: [],
  login: () => {},
  logout: () => {},
  renewToken: () => {},
  isAuth: (): boolean => false,
  getPermissionsByModule: (): Permissions[] => [],
})

export const useAuth = (): IAuthContext => useContext(OldAuthContext)

const serv = new AuthService()

if (
  window.location.href.indexOf('signin-callback') === -1 &&
  window.location.pathname &&
  window.location.pathname.length > 1
) {
  localStorage.setItem('originationURI', window.location.href)
}

export const OldAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  const [user, setUser] = useState<Oidc.User | undefined>(undefined)
  const [profileBusiness, setProfileBusiness] = useState<Business[]>([])
  const [profileClient, setProfileClient] = useState([])
  const [userPermissions, setPermissions] = useState<UserPermissions>({
    type: 'Buyer',
    isReseller: false,
  })
  const [authService, setAuthService] = useState<AuthService>()
  const [managePermissions, setManagePermissions] = useState<Permissions[]>([])

  const getAuthServices = useCallback((): AuthService => {
    if (authService === undefined) {
      throw new Error('Authentication services not available')
    }
    return authService
  }, [authService])

  const w = window as any
  w.hsConversationsOnReady = [() => {}]

  // useEffect(() => {
  //   if (user && user?.profile[TENANT_TYPE]?.Type !== INTERNAL) {
  //     const url = `${authUrl}/account/HubspotToken?userId=${user.profile[USER_ID]}`
  //     fetch(url, {
  //       method: 'GET',
  //     })
  //       .then((res) => {
  //         if (res.ok) return res.json()

  //         const err = new Error(res.statusText)
  //         throw err
  //       })
  //       .then((body) => {
  //         if (body && body !== '' && body !== undefined && body['token']) {
  //           w.hsConversationsSettings = {
  //             identificationEmail: user.profile[EMAIL],
  //             identificationToken: body['token'],
  //           }
  //         }
  //       })
  //       // eslint-disable-next-line no-console
  //       .catch((err) => console.warn('Unable to retrieve hubspot token'))
  //   }
  // }, [user, w])

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const services = serv

      setAuthService(services)
      try {
        const userData: any = await services.getUser()

        if (userData) {
          setUser(userData)
          setIsAuthenticated(true)
          const profileTenantType = userData.profile[TENANT_TYPE]
          const profileClientInfo = userData.profile[CLIENT_INFO] || null
          const profileBusinessInfo = userData.profile[BUSINESS_INFO] || null

          const profileClientInfoParse = Array.isArray(profileClientInfo)
            ? JSON.parse(profileClientInfo[0])
            : JSON.parse(profileClientInfo)
          if (profileTenantType.Type !== INTERNAL)
            setProfileClient(profileClientInfoParse)

          const isArray = Array.isArray(profileBusinessInfo)
          if (profileBusinessInfo && isArray) {
            const businessParse = profileBusinessInfo.map((item: any) => {
              const itemParse = JSON.parse(item || null)
              return itemParse
            })
            setProfileBusiness(businessParse)
          } else if (profileBusinessInfo && !isArray) {
            const businessItem: any = []
            businessItem.push(JSON.parse(profileBusinessInfo))
            setProfileBusiness(businessItem)
          }

          const isReseller = userData.profile[IS_RESELLER]
            ? JSON.parse(userData.profile[IS_RESELLER]?.toLowerCase())
            : false
          const type = profileClientInfoParse
            ? profileClientInfoParse.Type
            : profileTenantType
          setPermissions({
            type,
            isReseller,
          })

          updateUserType(type)

          localStorage.removeItem('originationURI')
        } else {
          await services.logoutCallback()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.log(error.toString())
      }
    }

    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserType])

  const values = useMemo(
    () => ({
      isAuthenticated,
      user,
      userPermissions,
      profileBusiness,
      profileClient,
      managePermissions,
      login: (): void => {
        getAuthServices().login()
      },
      logout: (): void => {
        window.localStorage.removeItem('selectedBusinessType')
        getAuthServices().logout()
      },
      renewToken: (): void => {
        getAuthServices().renewToken()
      },
      isAuth: (): boolean => {
        return AuthService.isAuth()
      },
      handleSetManagePermissions: (permissions: Permissions[]): void => {
        setManagePermissions(permissions)
      },
      getPermissionsByModule: (moduleId?: string): Permissions[] => {
        if (moduleId == null) {
          return managePermissions
        }
        return managePermissions?.filter((x) => x.code?.startsWith(moduleId))
      },
    }),
    [
      isAuthenticated,
      user,
      userPermissions,
      profileBusiness,
      getAuthServices,
      profileClient,
      managePermissions,
    ]
  )

  return (
    <OldAuthContext.Provider value={values}>{children}</OldAuthContext.Provider>
  )
}
