import React, { memo } from 'react'
// eslint-disable-next-line import/extensions
import { Root } from './styles'
import { Box, MainContent } from 'everchain-uilibrary'

interface Props {
  children: React.ReactNode
}

const RMSMainLayout: React.FC<Props> = ({ children }: Props) => {
  return (
    <Root>
      <Box display="flex" flexDirection="row" height="inherit">
        <MainContent>{children}</MainContent>
      </Box>
    </Root>
  )
}

export default memo(RMSMainLayout)
