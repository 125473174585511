/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import {
  Box,
  Colors,
  ErrorToast,
  ModalDialog,
  Step,
  SuccessToast,
  SwitchType,
  Typography,
  UploadDragDrop,
  fParseXlsxFile,
} from 'everchain-uilibrary'
import { useHistory } from 'react-router-dom'
import { RECALL_ACCOUNT_FILE_VALIDATION } from 'src/presentation/routes'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { useCustomQuery } from 'src/infra/reactQuery'
import { useGetBusinessessId, useGetBusinessessIdObject } from 'src/utils/user'
import { AccountRetrievalType } from 'src/utils/constants'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { useMutation } from '@tanstack/react-query'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useGetUserBusinessType } from 'src/context/UserContext'
import { getStandardUri } from 'src/utils/common'

interface LoadRecallAccountsModalProps {
  open: boolean
  setOpenLoadModal: any
  modalTitle: string
  businessOperations: IBusinessGetOperations
  operationType: AccountRetrievalType
  accountPostOperations: IAccountPostOperations
}

export interface FileParseResult {
  passedValidation: boolean
  errorMessage: string
  data: any[]
}

const LoadRecallAccountsModal: React.FC<LoadRecallAccountsModalProps> = ({
  open,
  setOpenLoadModal,
  modalTitle,
  businessOperations,
  operationType,
  accountPostOperations,
}: LoadRecallAccountsModalProps) => {
  const history = useHistory()
  const [files, setFiles] = useState<File[]>([])
  const [switchChecked, setSwitchChecked] = useState<any>(false)
  const handleCheckSwitch = (value: any) => {
    setFiles([])
    setSwitchChecked(value)
  }
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [businessIds, setBusinessIds] = useState<string[]>([])
  const [selectedOriginalBusiness, setSelectedOriginalBusiness] =
    useState<string>()
  const [openConfirmationModal, setOpenConfirmationModal] = useState<any>(false)
  const [selectedBusinessNames, setSelectedBusinessNames] = useState<any>()

  const handleSelectOriginalBusiness = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedOriginalBusiness(event.target.value as string)
  }
  const handleSelectBusiness = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setBusinessIds(event.target.value as string[])
  }

  const businessId = useGetBusinessessId()
  const currenctBusinessObject = useGetBusinessessIdObject()
  const { isVendor, isCreditor } = useGetUserBusinessType()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const { data, isFetched, refetch } = useCustomQuery(
    ['getPlacedAccounts', isCreditor, isVendor],
    async () => businessOperations.getPlacedBusiness(businessId, operationType),
    { cacheTime: 0, enabled: !isInternal }
  )

  const mutationProcessRecallBusinessAccounts = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processRecallBusinessAccounts(
        params.businessId,
        params.accounts
      )
    },
    onSuccess: async (response: any) => {
      if (!response.data) {
        setIsProcessing(false)
        setOpenConfirmationModal(false)
        setOpenLoadModal(false)
        setBusinessIds([])
        setSelectedBusinessNames('')
        SuccessToast('Accounts recalled successfully')
        refetch()
      } else {
        setIsProcessing(false)
        setOpenConfirmationModal(false)
        setOpenLoadModal(false)
        setBusinessIds([])
        setSelectedBusinessNames('')
        ErrorToast('There was an error while recalling the accounts')
        refetch()
      }
    },
    onError: async (response: any) => {
      setIsProcessing(false)
      setOpenLoadModal(false)
    },
  })
  useEffect(() => {
    setSelectedOriginalBusiness('')
    setBusinessIds([])
  }, [isVendor, isCreditor])

  const mutationProcessReturnBusinessAccounts = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processReturnBusinessAccounts(
        params.businessId,
        params.accounts
      )
    },
    onSuccess: async (response: any) => {
      if (!response.data) {
        setIsProcessing(false)
        setOpenConfirmationModal(false)
        setOpenLoadModal(false)
        setBusinessIds([])
        setSelectedOriginalBusiness('')
        setSelectedBusinessNames('')
        SuccessToast('Accounts returned successfully')
        refetch()
      } else {
        setIsProcessing(false)
        setOpenConfirmationModal(false)
        setOpenLoadModal(false)
        setBusinessIds([])
        setSelectedOriginalBusiness('')
        setSelectedBusinessNames('')
        ErrorToast('There was an error while returning the accounts')
        refetch()
      }
    },
    onError: async (response: any) => {
      setIsProcessing(false)
      setOpenLoadModal(false)
      setBusinessIds([])
      setSelectedOriginalBusiness('')
    },
  })

  const handleRenderValue = (selected: any) => {
    const dataSelected = selected as string[]

    const listName = data?.businessList.reduce(
      (acc: string[], item): string[] => {
        const names = [...acc]
        if (
          Array.isArray(dataSelected) &&
          dataSelected?.includes(item.businessId)
        ) {
          names.push(item.businessName)
        }
        return names
      },
      []
    )

    const nameList = listName?.join(', ')
    setSelectedBusinessNames(nameList)

    return nameList
  }

  const handleProcessBusinessAccount = () => {
    setIsProcessing(true)
    const accounts = data?.businessList.filter((x) =>
      businessIds.includes(x.businessId)
    )

    var params = {
      businessId: selectedOriginalBusiness,
      accounts: accounts,
    }

    if (operationType === AccountRetrievalType.Recall) {
      mutationProcessRecallBusinessAccounts.mutate(params)
    } else {
      mutationProcessReturnBusinessAccounts.mutate(params)
    }
  }

  const getOperationBusinessType = () => {
    return operationType === AccountRetrievalType.Recall
      ? 'vendor(s)'
      : 'creditor(s)'
  }
  const getOperationBusinessOriginalType = () => {
    return operationType === AccountRetrievalType.Recall ? 'creditor' : 'vendor'
  }
  const handleGetButtonOkText = () => {
    return !switchChecked
      ? 'Upload'
      : operationType === AccountRetrievalType.Recall
      ? 'Recall'
      : 'Return'
  }

  const handleGetLabelText = () => {
    return `Select the ${getOperationBusinessType()}`
  }
  const handleGetLabelOriginalText = () => {
    return `Select the ${getOperationBusinessOriginalType()}`
  }

  const handleOpenConfirmationModal = () => {
    if (
      businessIds.length > 0 &&
      selectedOriginalBusiness !== undefined &&
      selectedOriginalBusiness !== ''
    ) {
      setOpenConfirmationModal(open)
    } else {
      ErrorToast(
        `You need to select at least one ${getOperationBusinessType()} and one ${getOperationBusinessOriginalType()} to recall/return accounts`
      )
    }
  }

  const handleConfirmationMessage = () => {
    const retrievalType =
      operationType === AccountRetrievalType.Recall
        ? 'recalling all accounts from'
        : 'returning all accounts to'
    return `Do you confirm ${retrievalType} the ${getOperationBusinessType()}: ${selectedBusinessNames} ?`
  }
  const getOriginalBusinessComponent = () => {
    return (
      <>
        <Select
          label={handleGetLabelOriginalText()}
          placeholder="Select the business"
          value={selectedOriginalBusiness}
          onChange={handleSelectOriginalBusiness}
          style={{ minWidth: '380px', maxWidth: '380px' }}
        >
          {currenctBusinessObject &&
            isFetched &&
            currenctBusinessObject.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
        </Select>
      </>
    )
  }

  return (
    <>
      <ModalDialog
        isOpen={open}
        buttonOkText={handleGetButtonOkText()}
        onClose={() => {
          setOpenLoadModal(false)
        }}
        isForm={isFetched && data?.businessList.length === 0}
        onContinue={() => {
          if (!switchChecked) {
            if (files[0]) {
              fParseXlsxFile(files[0]).then((result: any) => {
                history.push({
                  pathname: getStandardUri(RECALL_ACCOUNT_FILE_VALIDATION),
                  state: {
                    fileData: result.data,
                    fileUploaded: files[0],
                    businessSeleted: selectedOriginalBusiness,
                  },
                })
              })
            }
          } else {
            handleOpenConfirmationModal()
          }
        }}
        header={modalTitle}
        style={{ minWidth: '450px', minHeight: '300px' }}
      >
        <SwitchType
          checked={switchChecked}
          onChange={(e) => handleCheckSwitch(e.target.checked)}
          variant="primary"
          primary="File"
          secondary="Business"
        ></SwitchType>
        <List>
          {!switchChecked ? (
            <ListItem style={{ paddingBottom: '14px' }}>
              <Box display="flex" flexDirection="column">
                <Step
                  title={handleGetLabelOriginalText()}
                  stepNumber="1"
                  completed={
                    selectedOriginalBusiness !== '' &&
                    selectedOriginalBusiness !== undefined
                  }
                >
                  {getOriginalBusinessComponent()}
                </Step>

                <Step
                  title="Upload your file"
                  stepNumber="2"
                  completed={files[0] !== undefined}
                >
                  <UploadDragDrop
                    disabled={
                      selectedOriginalBusiness === '' ||
                      selectedOriginalBusiness === undefined
                    }
                    files={files}
                    setFiles={setFiles}
                    hideUploadButton={true}
                  />
                </Step>
              </Box>
            </ListItem>
          ) : (
            <ListItem style={{ paddingBottom: '14px' }}>
              {isFetched && data?.businessList.length === 0 ? (
                <Typography
                  color={Colors.black}
                  style={{ marginTop: 50, marginLeft: 15 }}
                >
                  There are no accounts placed with any{' '}
                  {getOperationBusinessType()} at the moment.
                </Typography>
              ) : (
                <Box display="flex" flexDirection="column">
                  <Typography color={Colors.black}>
                    All accounts will be{' '}
                    {operationType === AccountRetrievalType.Recall
                      ? 'recalled from'
                      : 'returned to'}{' '}
                    the selected {getOperationBusinessType()}
                  </Typography>
                  <Step
                    title={handleGetLabelOriginalText()}
                    stepNumber="1"
                    completed={
                      selectedOriginalBusiness !== '' &&
                      selectedOriginalBusiness !== undefined
                    }
                  >
                    {getOriginalBusinessComponent()}
                  </Step>
                  <Step
                    title={handleGetLabelText()}
                    stepNumber="2"
                    completed={businessIds.length > 0}
                  >
                    <Select
                      label={handleGetLabelText()}
                      placeholder="Select the business"
                      multiple
                      value={businessIds}
                      renderValue={handleRenderValue}
                      onChange={handleSelectBusiness}
                      style={{ minWidth: '380px', maxWidth: '380px' }}
                    >
                      {data?.businessList.length &&
                        isFetched &&
                        data?.businessList.map((item) => (
                          <MenuItem
                            key={item.businessId}
                            value={item.businessId}
                          >
                            <Checkbox
                              checked={
                                businessIds.indexOf(item.businessId) > -1
                              }
                            />
                            <ListItemText primary={item.businessName} />
                          </MenuItem>
                        ))}
                    </Select>
                  </Step>
                </Box>
              )}
            </ListItem>
          )}
        </List>
      </ModalDialog>
      <ModalDialog
        header="Confirmation"
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        isLoading={isProcessing}
        buttonOkText="Yes"
        buttonCancelText="No"
        onContinue={() => {
          handleProcessBusinessAccount()
        }}
      >
        <Typography
          color={Colors.primary}
          variant="caption"
          style={{ fontSize: 14 }}
          isLoading={isProcessing}
        >
          {handleConfirmationMessage()}
        </Typography>
      </ModalDialog>
    </>
  )
}

export default LoadRecallAccountsModal
