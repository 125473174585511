/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { DatePicker, Group } from 'everchain-uilibrary'
import { dateThreeMonthsPast } from 'src/utils/date'

interface DateAccountFilterParams {
  onDateToFilterUpdate: (date: Date | null) => void
  onDateFromFilterUpdate: (date: Date | null) => void
  selectedFromDate?: string
  selectedToDate?: string
}

const DateAccountFilter: React.FC<DateAccountFilterParams> = ({
  onDateToFilterUpdate,
  onDateFromFilterUpdate,
  selectedFromDate,
  selectedToDate,
}) => {
  const [fromDate, setFromDate] = useState<any>(
    selectedFromDate ? new Date(selectedFromDate) : dateThreeMonthsPast()
  )
  const [toDate, setToDate] = useState<any>(
    selectedToDate ? new Date(selectedToDate) : new Date()
  )

  const handleChangeDateFrom = (date: Date) => {
    if (date > toDate) {
      onDateFromFilterUpdate(date)
      onDateToFilterUpdate(date)
      setFromDate(date)
      setToDate(date)
    } else {
      onDateFromFilterUpdate(date)
      setFromDate(date)
    }
  }

  const handleChangeDateTo = (date: Date) => {
    if (date < fromDate) {
      onDateFromFilterUpdate(date)
      onDateToFilterUpdate(date)
      setFromDate(date)
      setToDate(date)
    } else {
      onDateToFilterUpdate(date)
      setToDate(date)
    }
  }

  return (
    <Group alignItems="flex-end">
      <DatePicker
        value={fromDate}
        onChange={(date) => {
          if (date) handleChangeDateFrom(date)
        }}
        disablePast={false}
      />
      <DatePicker
        value={toDate}
        onChange={(date) => {
          if (date) handleChangeDateTo(date)
        }}
        disablePast={false}
      />
    </Group>
  )
}

export default DateAccountFilter
