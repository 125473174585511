export class ValidationRules {
  rules: Record<string, any> = {}

  required(message: string) {
    this.rules.required = { value: true, message }
    return this
  }

  minLength(value: number, message: string) {
    this.rules.minLength = { value, message }
    return this
  }

  maxLength(value: number, message: string) {
    this.rules.maxLength = { value, message }
    return this
  }

  max(value: number, message: string) {
    this.rules.max = { value, message }
    return this
  }
  min(value: number, message: string) {
    this.rules.min = { value, message }
    return this
  }

  pattern(pattern: RegExp, message: string) {
    this.rules.pattern = { value: pattern, message }
    return this
  }

  functionValidation(
    validateFn?: (value: any) => boolean | string,
    message?: string
  ) {
    if (validateFn) {
      this.rules.validate = { value: validateFn, message }
    }
    return this
  }

  get() {
    return this.rules
  }
}
export interface ValidationRulesDictionary {
  [key: string]: ValidationRules
}
