import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IBusinessPostOperations } from 'src/domain/features/post/business/business'
import { ApiError } from 'src/domain/models/api/api'
import { BusinessSettingsCreateEditRequest } from 'src/domain/models/business'
import * as urls from './urls'

export class BusinessPostData implements IBusinessPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async createBusinessSettings(
    request: BusinessSettingsCreateEditRequest
  ): Promise<string | ApiError> {
    return this.httpClient.post(urls.BusinessCreateBusinessSettings, request)
  }

  async editBusinessSettings(
    request: BusinessSettingsCreateEditRequest
  ): Promise<string | ApiError> {
    return this.httpClient.post(urls.BusinessEditBusinessSettings, request)
  }
}
