import React from 'react'
import { Box, Typography } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { AccessDeniedContent } from './styles'
import ToolbarMain from 'src/presentation/layouts/Main/ToolbarMain'

const AccessDenied = () => {
  return (
    <>
      <ToolbarMain showSearchBar={false} />
      <Box data-test-id="access-denied">
        <AccessDeniedContent>
          <Box
            width={180}
            height={180}
            className="icon-fade"
            textAlign="center"
          >
            <LockIcon
              style={{
                fontSize: 140,
              }}
            />
          </Box>
          <Typography variant="h2" className="subtitle">
            Access Denied
          </Typography>
          <Typography color="textSecondary">
            <Box component="span" fontSize={20}>
              You don't have permissions to access this page
            </Box>
          </Typography>
        </AccessDeniedContent>
      </Box>
    </>
  )
}

export default AccessDenied
