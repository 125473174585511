import React from 'react'
import { makeFilesData } from 'src/main/factories/feature/get/Files'
import { makeAccountPostData } from 'src/main/factories/feature/post/Account'
import RecallFileValidation from 'src/presentation/pages/Accounts/LoadRecallAccounts/RecallFileValidation'

const makeRecallFileValidation: React.FC = () => {
  return (
    <RecallFileValidation
      fileOperations={makeFilesData()}
      accountPostOperations={makeAccountPostData()}
    />
  )
}

export default makeRecallFileValidation
