import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import { ApiError } from 'src/domain/models/api/api'
import { FileValidationResult } from 'src/domain/models/file'
import { TransactionFileData } from 'src/domain/models/transaction'
import { ReportBouncedTransaction, TransactionProcessAccountFile } from './urls'

export class TransactionPostData implements ITransactionPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async processTransactionFile(
    businessId: string,
    fileData: TransactionFileData[],
    fileUploaded: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('businessId', businessId)
    form.append('transactionAccounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(TransactionProcessAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async reportBouncedTransaction(
    paymentId: number,
    createdBy: string
  ): Promise<ApiError> {
    return this.httpClient.post(
      `${ReportBouncedTransaction}?paymentId=${paymentId}&createdBy=${createdBy}`
    )
  }
}
