import { useContext, useState, useEffect } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useGetUserBusinessType } from 'src/context/UserContext'

export const useIsVendorSelected = () => {
  const storedIsVendorSelected = localStorage.getItem('isVendorSelected')
  const [isVendorSelected, setIsVendorSelected] = useState(
    storedIsVendorSelected ? JSON.parse(storedIsVendorSelected) : false
  )

  const updateIsVendorSelected = (type: any) => {
    setIsVendorSelected(type)
    localStorage.setItem('isVendorSelected', JSON.stringify(type))
  }

  useEffect(() => {
    if (storedIsVendorSelected) {
      setIsVendorSelected(JSON.parse(storedIsVendorSelected))
    }
  }, [storedIsVendorSelected])

  return { isVendorSelected, updateIsVendorSelected }
}

export const useIsMultipleBussines = () => {
  const { profileBusiness } = useContext(AuthContext)

  const isVendor =
    profileBusiness.find(
      (x) =>
        x.Type.toLocaleLowerCase() === 'agency' ||
        x.Type.toLocaleLowerCase() === 'buyer'
    ) !== undefined

  const isCreditor =
    profileBusiness.find((x) => x.Type.toLocaleLowerCase() === 'seller') !==
    undefined

  return isVendor && isCreditor
}

export const profileCountry = () => {
  const ProfileClient = () => {
    const { profileClient } = useContext(AuthContext)
    return profileClient
  }
  return ProfileClient()?.Country
}

export const useGetBusinessessId = () => {
  const filteredBusinesses = useGetBusinessessIdObject()
  return filteredBusinesses?.map((x) => x.id.toString())
}

export const useGetBusinessessIdObject = () => {
  const { profileBusiness } = useContext(AuthContext)
  const { isCreditor } = useGetUserBusinessType()

  const filteredBusinesses = profileBusiness.filter((x) =>
    isCreditor
      ? x.Type.toLocaleLowerCase() === 'seller'
      : x.Type.toLocaleLowerCase() === 'agency' ||
        x.Type.toLocaleLowerCase() === 'buyer'
  )

  return filteredBusinesses.map((x) => ({
    id: x.Id.toString(),
    name: x.Name,
    type: x.Type,
  }))
}
