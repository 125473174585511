import React from 'react'
import { makeFilesData } from 'src/main/factories/feature/get/Files'
import { makeTransactionPostData } from 'src/main/factories/feature/post/Transaction'
import TransactionFileValidation from 'src/presentation/pages/Accounts/LoadTransactions/TransactionFileValidation'

const makeRecallFileValidation: React.FC = () => {
  return (
    <TransactionFileValidation
      fileOperations={makeFilesData()}
      transactionPostOperations={makeTransactionPostData()}
    />
  )
}

export default makeRecallFileValidation
