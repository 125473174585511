import {
  ValidationRules,
  ValidationRulesDictionary,
} from 'src/validation/validation'

const makeBusinessSettingsValidation: ValidationRulesDictionary = {
  sftpServiceProvider: new ValidationRules().required(
    'SFTP Service Provider is required'
  ),
  businessId: new ValidationRules().required('Business is required'),
}
export default makeBusinessSettingsValidation
