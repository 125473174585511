import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IReportGetOperations } from 'src/domain/features/get/report/report'
import { GeneratedFile } from 'src/domain/models/file'
import { ReportsGetReport } from './urls'

export class ReportGetData implements IReportGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getRemittanceReport(
    dateFrom: Date,
    dateTo: Date,
    creditorId?: string,
    vendorId?: string
  ): Promise<GeneratedFile> {
    const dateFromFilter = `dateFrom= ${dateFrom} `
    const dateToFilter = `&dateTo= ${dateTo} `
    const creditorFilter = creditorId ? `&creditorId= ${creditorId} ` : ''
    const vendorFilter = vendorId ? `&vendorId= ${vendorId} ` : ''
    const response = await this.httpClient.get(
      `${ReportsGetReport}?${dateFromFilter}${dateToFilter}${creditorFilter}${vendorFilter}`
    )

    return response.data
  }
}
