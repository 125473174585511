import React from 'react'
import BusinessList from './BusinessList/index'
import { Content, Header } from 'everchain-uilibrary'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IBusinessPostOperations } from 'src/domain/features/post/business/business'
import makeBusinessSettingsValidation from 'src/main/factories/validation/businessSettings'

interface BusinessSettingsParams {
  businessGetOperations?: IBusinessGetOperations
  businessPostOperations?: IBusinessPostOperations
}

const BusinessSettings: React.FC<BusinessSettingsParams> = ({
  businessGetOperations,
  businessPostOperations,
}: BusinessSettingsParams) => {
  return (
    <Content>
      <Header showGoBack={false} title="Business Manager" />
      <BusinessList
        businessGetOperations={businessGetOperations}
        businessPostOperations={businessPostOperations}
        validation={makeBusinessSettingsValidation}
      />
    </Content>
  )
}

export default BusinessSettings
