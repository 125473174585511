import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

const NotFound: React.FC = () => {
  return (
    <>
      <Box data-test-id="not-found" padding={4} mt={4}>
        <Container>
          <Typography variant="h2">Ops, Page not found</Typography>
        </Container>
      </Box>
    </>
  )
}

export default NotFound
